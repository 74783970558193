<template>
  <ClientOnly>
    <template #fallback>
      <div class="rounded-lg w-full h-[36px] bg-gray-100 animate-pulse"></div>
    </template>
    <Popover as="div" class="text-sm leading-3 md:relative">
      <PopoverButton v-if="isTopBasketCount" class="relative lg:inline-block block focus:outline-none" @click="toggleBasketContent">
        <div>
          <slot/>
        </div>
        <span v-if="basketCount>0" class="absolute top-0 right-0 h-3.5 w-auto px-1.5 -translate-y-1/2 -translate-x-1 md:translate-x-1 transform rounded-full bg-secondary ring-2 ring-white flex items-center justify-center text-white font-bold text-[9px]">{{ basketCount }}</span>
      </PopoverButton>
      <PopoverButton v-else class="group basket-btn" @click="toggleBasketContent">
        <div>
          <slot/>
        </div>
        <div class="flex items-center justify-center absolute h-3.5 w-3.5 top-0 right-0 -mt-0.5 -mr-0.5 bg-red-500 rounded-full text-white font-bold text-[8px]" v-if="basketCount>0">
          <span>{{ basketCount }}</span>
        </div>
      </PopoverButton>

      <transition
          enter-active-class="transition ease-out duration-200"
          enter-from-class="opacity-0 -translate-y-2"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="transition ease-in duration-150"
          leave-from-class="opacity-100 translate-y-0"
          leave-to-class="opacity-0 -translate-y-2"
      >
        <OnClickOutside class="absolute inset-x-0 z-full top-full mt-px bg-white drop-shadow-xl md:left-auto md:right-0 md:mt-3 md:-mr-2 md:w-96 md:rounded-lg text-primary border"
                        @trigger="toggleBasketContent" v-if="isBasketContentVisible">
          <PopoverPanel static>
            <div class="absolute bottom-full w-6 right-0 -translate-x-1/2 overflow-hidden inline-block">
              <div class=" h-4 w-4 bg-white border rotate-45 transform origin-bottom-left"></div>
            </div>

            <h2 class="sr-only">{{ $t('basket.shopping-cart') }}</h2>
            <div class="relative px-5 py-6 sm:gap-5">
              <div class="flex justify-between items-center mb-5">
                <div class="text-base font-medium">{{ $t('navigation.basket') }}</div>
                <XMarkIcon class="h-4 w-4 cursor-pointer" @click="isBasketContentVisible=false"/>
              </div>

              <div class="h-full max-h-[16rem] sm:max-h-[30rem] overflow-y-auto">
                <div class="flex flex-col justify-center items-center font-medium" v-if="basketCount <= 0 && !basketLoading">
                  <div class="empty-cart">
                    <EmptyCartIllustration class="fill-primary w-12"/>
                  </div>
                  <div class="mt-5 font-medium">{{ $t('basket.basket-empty') }}</div>
                </div>

                <template v-else-if="basketCount > 0 && !basketLoading">
                  <section aria-labelledby="cart-heading">
                    <ul role="list" class="divide-y divide-gray-200">
                      <li v-for="(product, index) in activeBasketItems" :key="product.id" class="flex py-2">
                        <div class="flex-shrink-0">
                          <NuxtImg :src="getImage(product.thumbnail)" class="h-12 w-12 rounded-md object-cover object-center sm:h-12 sm:w-12"/>
                        </div>

                        <div class="ml-2 flex flex-1 flex-col sm:ml-3">
                          <div>
                            <div class="flex justify-between">
                              <p class="text-sm">
                                <NuxtLinkLocale :to="checkAndAddSlash(product.href)" class="font-medium hover:text-gray-800">{{ product.title }}</NuxtLinkLocale>
                                <SectionColorAndSizeAttributeLineForCart :attributes="product.attributes" />
                                <p class="text-sm text-gray-500" v-if="showModelIdInBasket && product.attributes?.model_id">
                                  {{ $t('basket.product-code') }} : {{product.attributes?.model_id}}
                                </p>
                              </p>
                              <div class="flex justify-end flex-col items-end">
                                <p class="text-base font-medium line-through text-gray-400" v-if="product?.old_unit_price && product?.old_unit_price != product.unit_price && product?.old_total_price > product.total_price">
                                  {{ formatPrice( product.old_total_price, product.currency) }}
                                </p>
                                <p class="ml-3 text-sm font-medium whitespace-nowrap">
                                  {{ formatPrice( product.total_price, product.currency) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="flex flex-1 justify-between">
                            <p class="flex space-x-2 text-xs font-medium text-gray-700">
                              <span>{{ product.qty }} {{ getAttributeName(product) }}</span>
                            </p>
                            <div class="ml-4">
                              <button type="button" class="text-sm font-medium text-gray-500 hover:text-red-600" @click="deleteBasket(product.id)" v-if="offerStatus === 0">
                                <span>{{ $t('app.remove') }}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </section>
                </template>
              </div>
            </div>

            <div class="sticky bottom-0 bg-white" v-if="basketCount > 0 && !basketLoading">

              <div class="text-base border-b border-gray-200">
                <div class="py-3 px-4 flex items-center justify-between text-base">
                  <div class="uppercase font-medium">{{ $t('basket.bottom-summary.total') }}</div>
                  <div class="flex items-center space-x-1.5">
                    <div class="discount-percent-label" v-if="priceDiffPercentForCart >0">%{{priceDiffPercentForCart}}</div>
                    <div class="font-gray-500 line-through text-xs" v-if="isOldTotalExists">{{ oldBasketTotalText }}</div>
                    <div class="text-secondary font-bold">{{ basketTotalText }}</div>
                  </div>
                </div>
              </div>

              <div class="grid grid-cols-2 space-y-0 ">
                <div
                    class="flow-root border-r border-gray-200 hover:text-secondary px-5 py-5 transition ease-in-out duration-150">
                  <NuxtLinkLocale to="/cart" class="-m-3 p-3 flex items-center justify-center text-base font-medium " @click="toggleBasketContent()">
                    {{ $t('basket.go-to-basket') }}
                  </NuxtLinkLocale>
                </div>

                <div class="flow-root px-5 py-5 hover:text-secondary transition ease-in-out duration-150">
                  <a href="/checkout" class="-m-3 p-3 flex items-center justify-center text-base font-medium">
                    {{ $t('basket.go-to-checkout') }}
                  </a>
                </div>
              </div>

            </div>
          </PopoverPanel>
        </OnClickOutside>
      </transition>
    </Popover>
  </ClientOnly>
</template>

<script setup>
import {OnClickOutside} from '@vueuse/components';
import {XMarkIcon} from "@heroicons/vue/24/solid"
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import EmptyCartIllustration from '~/assets/images/empty-cart.svg';

const storeConfig = useStoreConfig()
const {showModelIdInBasket} = storeToRefs(storeConfig)

const {
  basketTotalText,
  oldBasketTotalText,
  basketCount,
  deleteBasket,
  basketItems,
  basketLoading,
  activeBasketItems,
  isBasketContentVisible,
  toggleBasketContent,
  offerStatus,
  priceDiffPercentForCart,
  isOldTotalExists,
  getAttributeName
} = await useBasket()

const props = defineProps(["isTopBasketCount"]);

</script>

<style lang="scss">
.basket-btn {
  @apply flex items-center focus:outline-none flex-nowrap p-2.5 relative;
}
</style>